@use "sass:map";
@use "sass:list";
@import "../variables";
@import "ensureVariable";

$breakpoints-names: map.keys($breakpoints);
@function get-custom-responsive-var($prefix, $currentBrIndex) {
  $brName: list.nth($breakpoints-names, $currentBrIndex);
  $var: "var(--" +#{$prefix}#{ensure-variable($brName, "_")}--themed;
  @if ($currentBrIndex > 1) {
    $var: $var + "," get-custom-responsive-var($prefix, $currentBrIndex - 1);
  }
  @return #{$var} + ")";
}

@mixin set-custom-themed-var($prop, $brName, $theme) {
  $var: #{$prop}#{ensure-variable($brName, "_")};
  @if $theme == "" {
    --#{$var}--themed: var(--#{$var});
  } @else {
    --#{$var}--themed: var(--#{$var}_#{$theme}, var(--#{$var}));
  }
}
@mixin set-custom-responsive-var($prop, $brName, $brVal) {
  $prefix: #{$prop};
  --#{$prefix}--responsive: #{get-custom-responsive-var(
      $prefix,
      list.index($breakpoints-names, $brName)
    )};
}

// set custom reponsive css variables based on input array
// ex:
// $colorVars: "c","ch";
// will generate  ----->
// @media (min-width: [breakpoint-val]) {
//   .[component] {
//     --c--responsive: var(--c--themed);
//     --ch--responsive: var(--ch--themed);
// }

@mixin set-custom-vars($props) {
  @each $theme in $themes {
    $themeClass: if($theme == "", ":root", ":root." + $theme);
    :global(#{$themeClass}) & {
      @each $brName, $brVal in $breakpoints {
        @each $prop in $props {
          @include set-custom-themed-var($prop, $brName, $theme);
        }
      }
    }
  }
  @each $brName, $brVal in $breakpoints {
    @media (min-width: #{$brVal}) {
      @each $prop in $props {
        @include set-custom-responsive-var($prop, $brName, $brVal);
      }
    }
  }
}
