.gridXs0 {
  flex-grow: 0;
  max-width: 0;
  flex-basis: 0;
  display: none;
}
.gridXs1 {
  flex-grow: 0;
  max-width: 8.333333%;
  flex-basis: 8.333333%;
  display: block;
}
.gridXs2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  display: block;
}
.gridXs3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  display: block;
}
.gridXs4 {
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  display: block;
}
.gridXs5 {
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
  display: block;
}
.gridXs6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  display: block;
}
.gridXs7 {
  flex-grow: 0;
  max-width: 58.333333%;
  flex-basis: 58.333333%;
  display: block;
}
.gridXs8 {
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  display: block;
}
.gridXs9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
  display: block;
}
.gridXs10 {
  flex-grow: 0;
  max-width: 83.333333%;
  flex-basis: 83.333333%;
  display: block;
}
.gridXs11 {
  flex-grow: 0;
  max-width: 91.666667%;
  flex-basis: 91.666667%;
  display: block;
}
.gridXs12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  display: block;
}

@media (min-width: 600px) {
  .gridSm1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
    display: block;
  }
  .gridSm2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    display: block;
  }
  .gridSm3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: block;
  }
  .gridSm4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
    display: block;
  }
  .gridSm5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
    display: block;
  }
  .gridSm6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    display: block;
  }
  .gridSm7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
    display: block;
  }
  .gridSm8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
    display: block;
  }
  .gridSm9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
    display: block;
  }
  .gridSm10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
    display: block;
  }
  .gridSm11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
    display: block;
  }
  .gridSm12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    display: block;
  }
}

@media (min-width: 960px) {
  .gridMd1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
    display: block;
  }
  .gridMd2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    display: block;
  }
  .gridMd3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: block;
  }
  .gridMd4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
    display: block;
  }
  .gridMd5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
    display: block;
  }
  .gridMd6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    display: block;
  }
  .gridMd7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
    display: block;
  }
  .gridMd8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
    display: block;
  }
  .gridMd9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
    display: block;
  }
  .gridMd10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
    display: block;
  }
  .gridMd11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
    display: block;
  }
  .gridMd12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    display: block;
  }
}

@media (min-width: 1280px) {
  .gridLg1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
    display: block;
  }
  .gridLg2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    display: block;
  }
  .gridLg3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: block;
  }
  .gridLg4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
    display: block;
  }
  .gridLg5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
    display: block;
  }
  .gridLg6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    display: block;
  }
  .gridLg7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
    display: block;
  }
  .gridLg8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
    display: block;
  }
  .gridLg9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
    display: block;
  }
  .gridLg10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
    display: block;
  }
  .gridLg11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
    display: block;
  }
  .gridLg12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    display: block;
  }
}

@media (min-width: 1920px) {
  .gridXl1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
    display: block;
  }
  .gridXl2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    display: block;
  }
  .gridXl3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: block;
  }
  .gridXl4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
    display: block;
  }
  .gridXl5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
    display: block;
  }
  .gridXl6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    display: block;
  }
  .gridXl7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
    display: block;
  }
  .gridXl8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
    display: block;
  }
  .gridXl9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
    display: block;
  }
  .gridXl10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
    display: block;
  }
  .gridXl11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
    display: block;
  }
  .gridXl12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
    display: block;
  }
}

.hidden {
  display: none !important;
}
