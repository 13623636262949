.bg {
  background: var(--bg-image) no-repeat center;
  background-size: cover;
}
.formCol {
  min-height: 100vh;
}

.form {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
}
.formBgTop {
  width: 100%;
  height: 10vh;
  background: var(--bg-image) no-repeat bottom;
  background-size: cover;
}
.formBgBottom {
  width: 100%;
  height: 10vh;
  background: var(--bg-image) no-repeat top;
  background-size: cover;
}

@media screen and (max-width: 500px) {
  .form {
    padding: 2rem;
  }

  .formCol {
    flex-direction: column;
    justify-content: space-between !important;
  }
}
